import './App.css';
import React, { useEffect, useState } from 'react';
import { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route, Outlet } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@mdi/font/css/materialdesignicons.min.css';


// Lazy load components
const Login = lazy(() => import('./Pages/Auth/login'));
const UserManagement = lazy(() => import('./Pages/userManagement'));
const Questionlists = lazy(() => import('./Pages/QuestionManagement/Questionlist'));
const SurveyList = lazy(() => import('./Pages/Survey/Survaylist'));
const Campaign = lazy(() => import('./Pages/Campaign'));
const SurveyForm = lazy(() => import('./Pages/Survey/SurveyForm'));
const SurveyQuestions = lazy(() => import('./Pages/Survey/Surveyquestion'));
const ThankYouPage = lazy(() => import('./Pages/Survey/Thankyou'));
const SurveyDetail = lazy(() => import('./Pages/Survey/SurveyDetails'));
const Terms =lazy(() => import('./Pages/Survey/Terms'));
function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(!!localStorage.getItem('token'));
  const [hasUserId, setHasUserId] = useState(!!localStorage.getItem('userId'));

  // Private route for authenticated users
  const PrivateOutlet = () => {
    return isLoggedIn ? <Outlet /> : <Login />;
  };

  // Private route for survey (checks if userId exists)hasUserId ? <Outlet /> :
  const PrivateVal = () => {
    return hasUserId ? <Outlet />: <SurveyForm />;
  };

  useEffect(() => {
    // Function to check localStorage and update the state accordingly
    const checkAuthStatus = () => {
      const token = localStorage.getItem('token');
      const userId = localStorage.getItem('userId');
      
      setIsLoggedIn(!!token);
      setHasUserId(!!userId);
    };

    // Initial check when the component mounts
    checkAuthStatus();

    // Set up an interval to periodically check localStorage (e.g., every 1 second)
    const intervalId = setInterval(() => {
      checkAuthStatus();
    }, 1000); // Adjust the interval as needed

    // Clean up the interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  return (
    <Router>
      <Suspense fallback="Loading....">
        <Routes>
          {/* Default login route */}
          <Route exact path="/" element={<SurveyForm />} />
          <Route path='/tech-admin' element={<Login/>}/>
          {/* Private routes for logged-in users */}
          <Route element={<PrivateOutlet />}>
            <Route path="/user-management" element={<UserManagement />} />
            <Route path="/question-management" element={<Questionlists />} />
            <Route path="/survey-management" element={<SurveyList />} />
            <Route path="/campaign-management" element={<Campaign />} />
            <Route path="/survey-Details/:id" element={<SurveyDetail />} />
          </Route>

          {/* Public Survey form route */}
          <Route path="/entry" element={<SurveyForm />} />

          {/* Private survey questions route based on userId */}
          <Route element={<PrivateVal />}>
            <Route path="/survey" element={<SurveyQuestions />} />
          
          </Route>
          <Route path="/submission" element={<ThankYouPage />} />
          <Route path="/terms&Conditions" element={<Terms />} />
        </Routes>
      </Suspense>
    </Router>
  );
}

export default App;
